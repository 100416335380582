function toggleMenu(menu:HTMLElement) {
    const ariaExpanded = menu.getAttribute('aria-expanded') === 'true';
    menu.setAttribute('aria-expanded', String(!ariaExpanded));
}

window.addEventListener('load', () => {
    const toggles = document.querySelectorAll('.mobile-menu-toggle');
    const menu = document.querySelector('.mobile-menu') as HTMLElement;

    if(!menu) {
        return;
    }

    toggles.forEach(toggle => {
        toggle.addEventListener('click', () => {
            toggleMenu(menu);
        });
    })
});