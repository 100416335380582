interface onLoadOptions {
    checkForElements?: string[];
}

type callbackFunction = () => void;

declare global {
    interface Window {
        onLoad: (callback: callbackFunction, options:onLoadOptions) => void
        domLoaded:(callback: callbackFunction) => void
    }
}

export function onLoad(callback: callbackFunction, options:onLoadOptions = {}) {
    const {checkForElements} = options;

    window.addEventListener('load', function() {
        if(checkForElements) {
            const elements = checkForElements.map(el => document.querySelector(el));
            if(elements.some(el => el === null)) {
                return;
            }
        }

        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.onLoad = onLoad;

/**
 * This function is a shorthand for the window DOMContentLoaded event
 * @param callback
 */
export function domLoaded(callback:callbackFunction) {
    document.addEventListener('DOMContentLoaded', function () {
        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.domLoaded = domLoaded;